/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Applications from "views/Applications.jsx";
import BBS from 'views/BBS';
import Buttons from "views/components/Buttons.jsx";
import Calendar from "views/Calendar.jsx";
import Charts from "views/Charts.jsx";
import Dashboard from "views/Dashboard.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import FAQ from 'views/FAQ';
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import GoogleMaps from "views/maps/GoogleMaps.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import Institutes from "views/Institutes";
import LocalAssistants from 'views/LocalAssistants';
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import PreApplications from "views/PreApplications.jsx";
import Programs from "views/Programs.jsx";
import PushNotifications from 'views/PushNotifications.jsx';
import PushTopics from "views/PushTopics";
import QAs from 'views/QAs';
import ReactTables from "views/tables/ReactTables.jsx";
import Register from "views/pages/Register.jsx";
import Regions from 'views/Regions';
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Timeline from "views/pages/Timeline.jsx";
import Typography from "views/components/Typography.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import Users from "views/Users.jsx";
import UnderConstruction from "views/UnderConstruction.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import VectorMap from "views/maps/VectorMap.jsx";
import Widgets from "views/Widgets.jsx";
import Wizard from "views/forms/Wizard.jsx";

const redirect = false;
const routes = [
  {
    path: "/dashboard",
    name: "대시보드",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/programs",
    name: "프로그램",
    icon: "nc-icon nc-spaceship",
    component: Programs,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "사용자",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "신청/지원",
    icon: "nc-icon nc-paper",
    state: "applicationCollapse",
    views: [
      {
        path: "/pre-applications",
        name: "캠프 상담 신청서",
        mini: "PRE",
        component: PreApplications,
        layout: "/admin"
      },
      {
        path: "/applications",
        name: "지원서",
        mini: "App",
        component: Applications,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: "푸시메시지",
    icon: "nc-icon nc-send",
    state: "pushNotificationCollapse",
    views: [
      {
        path: "/notifications",
        name: "발송 관리",
        mini: "PUSH",
        component: PushNotifications,
        layout: "/admin"
      },
      {
        path: "/pushTopics",
        name: "대상 관리",
        mini: "Topic",
        component: PushTopics,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/institutes",
    name: "교육기관",
    icon: "nc-icon nc-bank",
    component: Institutes,
    layout: "/admin"
  },
  {
    path: "/regions",
    name: "지역",
    icon: "nc-icon nc-world-2",
    component: Regions,
    layout: "/admin"
  },
  {
    path: "/local-assitants",
    name: "지역전문가",
    icon: "nc-icon nc-badge",
    component: LocalAssistants,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "고객응대",
    icon: "nc-icon nc-bulb-63",
    state: "pagesCollapse",
    views: [
      {
        path: "/qa",
        name: "질의응답",
        mini: "QA",
        component: QAs,
        layout: "/admin"
      },
      {
        path: "/faq",
        name: "자주물어보는 질문",
        mini: "FAQ",
        component: FAQ,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/boards",
    name: "피드",
    icon: "nc-icon nc-bullet-list-67",
    component: BBS,
    layout: "/admin"
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth"
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    collapse: true,
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin"
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin"
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin"
      }
    ]
  },
  {
    redirect: redirect,
    path: "/widgets",
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin"
  },
  {
    redirect: redirect,
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin"
  },
  {
    redirect: redirect,
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin"
  }
];

export default routes;
