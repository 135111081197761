import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFirestoreConnect } from 'react-redux-firebase';

import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from 'variables/constants';
import { regions } from 'variables/constants';
import { schoolAge } from 'variables/constants';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { isValidPhoneNumber } from 'helpers/validation';
import { isValidEmailAddress } from 'helpers/validation';


const initPreApplication = {
};

function PreApplicationForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [preApplication, setPreApplication] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initPreApplication));
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    program: { isValid: false, message: null },
    attendeeName: { isValid: false, message: null },
    attendeeGrade: { isValid: false, message: null },
    phone: { isValid: false, message: null },
    email: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('preApplications').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //verification
  useEffect(() => {
    console.log('preApplication', preApplication);
    const {
      program,
      attendeeName,
      attendeeGrade,
      gardianName,
      phone,
      email,
      comment
    } = preApplication;

    setVerification({
      program: { isValid: Boolean(program), message: '프로그램을 선택해주세요' },
      attendeeName: { isValid: attendeeName && attendeeName.length > 1, message: '두 글자 이상 입력해주세요' },
      attendeeGrade: { isValid: attendeeGrade && attendeeGrade.length > 1, message: '두 글자 이상 입력해주세요' },
      gardianName: { isValid: gardianName && gardianName.length > 1, message: '두 글자 이상 입력해주세요' },
      phone: { isValid: phone && isValidPhoneNumber(phone), message: '+821012345678 형식으로 입력해주세요' },
      email: { isValid: email && isValidEmailAddress(email), message: '정확한 이메일을 입력해주세요' }
    });

    setIsModified(props.currentItem === null
      ? !_.isEqual(initPreApplication, preApplication)
      : !_.isEqual(props.currentItem, preApplication)
    );
  }, [preApplication]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(verification, (value, key) => value.isValid !== true);
    console.log('invalidItems', invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification])

  useEffect(() => {
    console.log('isModified', isModified);
  }, [isModified]);

  useEffect(() => {
    console.log('isVerified', isVerified);
  }, [isVerified]);


  const verificationIndicator = (path) => {
    // console.log("path", path);
    const itemToVerify = _.get(verification, path);
    const normal_id = path.replace('.', '_');

    if (itemToVerify?.isValid) {
      return <i className="nc-icon nc-check-2" style={{ color: 'lightgreen', fontSize: '1.5em' }} />
    } else {
      return <><i className="nc-icon nc-alert-circle-i" style={{ color: 'red', fontSize: '1.5em' }} id={`${normal_id}_invalid`} />
        <UncontrolledTooltip target={`${normal_id}_invalid`}>{itemToVerify?.message}</UncontrolledTooltip>
      </>
    }
  }

  const onChange = (e, path, typeFn) => {
    const value = e.target.value;
    const newPreApplication = _.set(preApplication, path, typeFn ? typeFn(value) : value);
    console.log(newPreApplication);
    setPreApplication({ ...newPreApplication });
  }

  const onSave = async () => {
    console.log('press save');

    if (!isVerified) {
      basicAlert('값을 확인해주세요');
      return;
    }

    setOnSaving(true);

    const path = `preApplications/${docId}`;
    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }

    return firestore
      .collection('preApplications')
      .doc(docId)
      .set({
        ...preApplication,
        ...timeStamps
      }, { merge: true })
      .then(() => props.toggle())
      .catch(e => {
        if (e.code === "permission-denied") {
          basicAlert('유저 생성 권한이 없습니다');
        }
        setOnSaving(false);
      })

  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const renderCamp = () => <>
    <Row>
      <h4>캠프 신청 정보</h4>
    </Row>
    {/* 프로그램 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>프로그램</label></Col>
          <Col className="text-right">{verificationIndicator("program")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.program && preApplication.program.title}
            placeholder="프로그램을 선택해주세요."
            onChange={e => onChange(e, 'program.title')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 참석자 이름 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>참석자 이름</label></Col>
          <Col className="text-right">{verificationIndicator("attendeeName")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.attendeeName}
            placeholder="참석 학생의 이름을 입력해주세요"
            onChange={e => onChange(e, 'attendeeName')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 보호자 성함 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>보호자 성함</label></Col>
          <Col className="text-right">{verificationIndicator("gardianName")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.gardianName}
            placeholder="보호자의 성함을 입력해주세요"
            onChange={e => onChange(e, 'gardianName')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 보호자의 전화번호 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>보호자 연락처</label></Col>
          <Col className="text-right">{verificationIndicator("phone")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.phone}
            placeholder="보호자의 전화번호를 입력해주세요"
            onChange={e => onChange(e, 'phone')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 보호자 이메일 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>보호자 이메일</label></Col>
          <Col className="text-right">{verificationIndicator("email")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.email}
            placeholder="보호자의 이메일을 입력해주세요"
            onChange={e => onChange(e, 'email')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 특이사항 / 문의 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>특이사항 / 문의</label></Col>
        </Row>
        <FormGroup>
          <Input
            value={preApplication.comment}
            placeholder="특이사항 / 문의사항"
            onChange={e => onChange(e, 'comment')} />
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col className='text-right'>
        <Button color="primary" disabled={!isVerified || onSaving || !isModified} onClick={onSave}>저장</Button>
        <Button color="warning" onClick={onCancel}>취소</Button>
      </Col>
    </Row>
  </>


  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          {renderCamp()}
        </Form>

      </div>
    </>
  )
}

PreApplicationForm.propTypes = {

}

export default PreApplicationForm

