export const categories = [
  {
    value: "1", label: "단기 스쿨링", categories: [
      { value: "1-01", label: "어학" },
      { value: "1-02", label: "스포츠" },
      { value: "1-03", label: "STEM(과학,수학)" },
      { value: "1-04", label: "스쿨링" },
      { value: "1-05", label: "예체능" },
    ]
  },
  {
    value: "2", label: "전문직 연수", categories: [
      { value: "2-01", label: "판검사" },
      { value: "2-02", label: "의사" },
      { value: "2-03", label: "교수" },
      { value: "2-04", label: "기타" },
    ]
  },
  {
    value: "3", label: "조기유학(초,중,고)", categories: [
      { value: "3-01", label: "초등" },
      { value: "3-02", label: "중등" },
      { value: "3-03", label: "고등" },
    ]
  },
  {
    value: "4", label: "대학(원) 성인유학", categories: [
      { value: "4-01", label: "교육" },
      { value: "4-02", label: "심리" },
      { value: "4-03", label: "예체능" },
      { value: "4-04", label: "요리" },
    ]
  },
]



export const regions = [
  {
    value: '1',
    label: '미국',
    regions: [
      { value: "1-01", label: "캘리포니아" },
      { value: "1-02", label: "워싱턴" },
      { value: "1-03", label: "네바다" },
      { value: "1-04", label: "콜로라도" },
      { value: "1-05", label: "텍사스" },
      { value: "1-06", label: "유타" },
      { value: "1-07", label: "오레곤" },
      { value: "1-08", label: "일리노이" },
      { value: "1-09", label: "위스콘신" },
      { value: "1-10", label: "오하이오" },
      { value: "1-11", label: "미네소타" },
      { value: "1-12", label: "미시간" },
      { value: "1-13", label: "노스캐롤라이나" },
      { value: "1-14", label: "펜실베니아" },
      { value: "1-15", label: "뉴욕" },
      { value: "1-16", label: "메사추세츠" },
      { value: "1-17", label: "뉴져지" },
      { value: "1-18", label: "코네티컷" },
      { value: "1-19", label: "플로리다" },
      { value: "1-20", label: "아틀란타" },
    ]
  },
  {
    value: '2',
    label: '캐나다',
    regions: [
      { value: "2-01", label: "캐나다" },
    ]
  },
  {
    value: '3',
    label: '동남아시아',
    regions: [
      { value: "3-01", label: "말레이시아" },
      { value: "3-02", label: "필리핀" },
    ]
  },
];

export const schoolAge = [
  { value: '1', label: '초1' },
  { value: '2', label: '초2' },
  { value: '3', label: '초3' },
  { value: '4', label: '초4' },
  { value: '5', label: '초5' },
  { value: '6', label: '초6' },
  { value: '7', label: '중1' },
  { value: '8', label: '중2' },
  { value: '9', label: '중3' },
  { value: '10', label: '고1' },
  { value: '11', label: '고2' },
  { value: '12', label: '고3' },
  { value: '13', label: '대학' },
  { value: '14', label: '대학원' },
  { value: '15', label: '기타' },
]

export const profApplicationStatus = [
  "시작",
  "1차 서류 제출 완료",
  "합격 결과 대기중",
  "합격 결과 - 통과",
  "합격서류(2차) 제출 요망",
  "2차 서류 제출 완료",
  "검토중",
  "검토 완료",
  "DS - 2019 주소 입력 완료",
  "DS - 2019 신청 완료",
  "완료"
];