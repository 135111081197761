import { combineReducers } from 'redux';
import auth from './auth';
import program from './program';

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAuxJkiDYJWGhn92Mfgmqc9uP7_EnmI4Ek",
  authDomain: "act-abroad-1db23.firebaseapp.com",
  databaseURL: "https://act-abroad-1db23.firebaseio.com",
  projectId: "act-abroad-1db23",
  storageBucket: "act-abroad-1db23.appspot.com",
  messagingSenderId: "224504779606",
  appId: "1:224504779606:web:0561592dcf208ad8e77cf7",
  measurementId: "G-JQBY7PLJP0"
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore()
firebase.auth()
firebase.storage()
firebase.functions()


export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth,
  program,
})