import React from 'react'
import PropTypes from 'prop-types'

import Board from 'views/components/Board';

function LocalAssistants(props) {
  return (
    <div className="content">
      <Board
        title="지역"
        data={[]}
        columns={[
          {
            Header: "Category",
            accessor: "category"
          },
          {
            Header: "Title",
            accessor: "title"
          },
          {
            Header: "Region",
            accessor: "region"
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />

    </div>
  )
}

LocalAssistants.propTypes = {

}

export default LocalAssistants

