import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFirestoreConnect } from 'react-redux-firebase';

import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from 'variables/constants';
import { regions } from 'variables/constants';
import { schoolAge } from 'variables/constants';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { isValidPhoneNumber } from 'helpers/validation';
import { isValidEmailAddress } from 'helpers/validation';


const initInstitute = {
  photos: [], //{ file, description }
  sections: [], // title, content, contentType [wrappedText, markdown, text]
  checklist1: [
    { title: 'CV / Resume (국/영문)', type: 'file' },
    { title: '연구계획서 1~2 page 년 (국/영문)', type: 'file' },
    { title: '영어성적표 (TOEIC/TOEFL)', type: 'file' },
    { title: '여권사본', type: 'file' },
    { title: '추천서', type: 'file' },
  ],
  checklist2: [
    { title: '가족 여권 사본 1부씩', type: 'file' },
    { title: '재정보증서', type: 'file' },
    { title: 'DS - 2019', type: 'file' },
    { title: 'DS - 2019 받을 주소 [필수입력]', type: 'text', placeholder: '자택 또는 회사 주소를 입력해주세요' },
  ],
  checklist3: [
    { title: 'DS - 160 템플릿', type: 'file' },
    { title: '기타 수수료/ 결제료', type: 'file' },
    { title: '비자 인터뷰 날짜 선택', type: 'file' },
  ],
};

function InstituteForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [institute, setInstitute] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initInstitute));
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    fullname: { isValid: false, message: null },
    shortname: { isValid: false, message: null },
    majors: { isValid: false, message: null },
    description: { isValid: false, message: null },
    region1: { isValid: false, message: null },
    region2: { isValid: false, message: null },
    tags: { isValid: false, message: null },
    photos: { isValid: false, message: null },
    sections: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('institues').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //verification
  useEffect(() => {
    console.log('institute', institute);
    const {
      fullname,
      shortname,
      majors,
      description,
      region1,
      region2,
      tags,
      photos,
      sections,
      checklist1,
      checklist2,
      checklist3,
    } = institute;

    setVerification({
      fullname: { isValid: fullname && fullname.length > 5, message: '다섯글자 이상 입력해주세요.' },
      shortname: { isValid: shortname && shortname.length > 2, message: '두글자 이상 입력해주세요.' },
      majors: { isValid: majors?.split(',').length > 1, message: '전공분야를 하나 이상 입력해주세요' },
      description: { isValid: description && description.length > 2, message: '두글자 이상 입력해주세요.' },
      region1: { isValid: Boolean(region1), message: '지역 1 선택해주세요' },
      region2: { isValid: Boolean(region2), message: '지역 2 선택해주세요' },
      tags: { isValid: tags?.split(',').length > 1, message: '태그를 하나 이상 입력해주세요' },
      photos: { isValid: photos && photos.length > 0, message: '최소 이미지 하나는 추가해주세요' },
      sections: { isValid: sections && sections.length > 0, message: '최소 하나 이상의 섹션을 추가해주세요.' },
      checklist1: { isValid: checklist1 && checklist1.length > 0, message: '최소 하나 이상의 체크리스트를 추가해주세요.' },
      checklist2: { isValid: checklist2 && checklist2.length > 0, message: '최소 하나 이상의 체크리스트를 추가해주세요.' },
      checklist3: { isValid: checklist3 && checklist3.length > 0, message: '최소 하나 이상의 체크리스트를 추가해주세요.' },
    });

    setIsModified(props.currentItem === null
      ? !_.isEqual(initInstitute, institute)
      : !_.isEqual(props.currentItem, institute)
    );
  }, [institute]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(verification, (value, key) => value.isValid !== true);
    console.log('invalidItems', invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification])

  useEffect(() => {
    console.log('isModified', isModified);
  }, [isModified]);

  useEffect(() => {
    console.log('isVerified', isVerified);
  }, [isVerified]);


  const verificationIndicator = (path) => {
    // console.log("path", path);
    const itemToVerify = _.get(verification, path);
    const normal_id = path.replace('.', '_');

    if (itemToVerify?.isValid) {
      return <i className="nc-icon nc-check-2" style={{ color: 'lightgreen', fontSize: '1.5em' }} />
    } else {
      return <><i className="nc-icon nc-alert-circle-i" style={{ color: 'red', fontSize: '1.5em' }} id={`${normal_id}_invalid`} />
        <UncontrolledTooltip target={`${normal_id}_invalid`}>{itemToVerify?.message}</UncontrolledTooltip>
      </>
    }
  }

  const onChange = (e, path, typeFn) => {
    const value = e.target.value;
    const newInstitute = _.set(institute, path, typeFn ? typeFn(value) : value);
    console.log(newInstitute);
    setInstitute({ ...newInstitute });
  }

  const onSave = async () => {
    console.log('press save');

    if (!isVerified) {
      basicAlert('값을 확인해주세요');
      return;
    }

    setOnSaving(true);

    const { photos } = institute;
    const path = `institutes/${docId}`;
    // console.log('photos', photos);
    const needUploadFiles = photos.filter(item => item.file);
    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }

    if (needUploadFiles.length > 0) {
      const alreadyUploadedFiles = photos.filter(item => item.url);
      return firebase.uploadFiles(path, needUploadFiles.map(photo => photo.file))
        .then((uploadingInfo) => {
          // console.log('uploading info', uploadingInfo);
          return Promise.all(uploadingInfo.map(({ uploadTaskSnapshot }) => uploadTaskSnapshot.ref.getDownloadURL()))
        })
        .then(urls => {
          // console.log('urls', urls);
          const newlyUploadedPhotos = needUploadFiles.map((item, index) => ({ url: urls[index], description: item.description }));
          const newPhotos = [...alreadyUploadedFiles, ...newlyUploadedPhotos];
          // console.log('newPhotos', newPhotos);

          setInstitute({ ...institute, photos: newPhotos }); // this dose not change institute immediately
          // I just wrote this line for in case this modal is not closed.

          return firestore
            .collection('institutes')
            .doc(docId)
            .set({ ...institute, photos: newPhotos, ...timeStamps }, { merge: true })
            .then(() => props.toggle())
            .catch(e => {
              if (e.code === "permission-denied") {
                basicAlert('교육기관 저장 권한이 없습니다');
              }
              setOnSaving(false);
            })
        })
        .catch(err => {
          console.error('error uploading files', err)
          setOnSaving(false);
          basicAlert('교육기관 저장에 실패했습니다.');
        })
    } else {
      return firestore
        .collection('institutes')
        .doc(docId)
        .set({
          ...institute,
          ...timeStamps
        }, { merge: true })
        .then(() => props.toggle())
        .catch(e => {
          if (e.code === "permission-denied") {
            basicAlert('교육기관 생성 권한이 없습니다');
          }
          setOnSaving(false);
        })
    }
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const addPhoto = () => {
    setInstitute({ ...institute, photos: [...institute.photos, { file: null, description: '' }] });
  }

  const deletePhoto = (idx) => {
    const newPhotos = institute.photos.filter((item, i) => i !== idx);
    setInstitute({ ...institute, photos: newPhotos });
  }

  const addSection = () => {
    setInstitute({ ...institute, sections: [...institute.sections, { content: undefined, contentType: 'wrappedText', title: undefined }] });
  }

  const deleteSection = (idx) => {
    // console.log('idx', idx);
    const newSections = institute.sections.filter((item, i) => i !== idx);
    setInstitute({ ...institute, sections: newSections });
  }

  const addChecklist1 = () => {
    setInstitute({ ...institute, checklist1: [...institute.checklist1, { title: '', type: undefined }] });
  }

  const deleteChecklist1Item = (idx) => {
    // console.log('idx', idx);
    const newChecklist = institute.checklist1.filter((item, i) => i !== idx);
    // console.log('newChecklist', newChecklist);
    setInstitute({ ...institute, checklist1: newChecklist });
  }

  const addChecklist2 = () => {
    setInstitute({ ...institute, checklist2: [...institute.checklist2, { title: '', type: undefined }] });
  }

  const deleteChecklist2Item = (idx) => {
    // console.log('idx', idx);
    const newChecklist = institute.checklist2.filter((item, i) => i !== idx);
    // console.log('newChecklist', newChecklist);
    setInstitute({ ...institute, checklist2: newChecklist });
  }

  const addChecklist3 = () => {
    setInstitute({ ...institute, checklist3: [...institute.checklist3, { title: '', type: undefined }] });
  }

  const deleteChecklist3Item = (idx) => {
    // console.log('idx', idx);
    const newChecklist = institute.checklist3.filter((item, i) => i !== idx);
    // console.log('newChecklist', newChecklist);
    setInstitute({ ...institute, checklist3: newChecklist });
  }

  const renderInstitute = () => <>
    <Row>
      <h4>교육기관(학교) 정보</h4>
    </Row>

    {/* 학교명 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>학교 이름(전체 이름)</label></Col>
          <Col className="text-right">{verificationIndicator("fullname")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={institute?.fullname}
            placeholder="전체 이름을 입력해주세요. 예) University of Califonia, Los Angeles"
            onChange={e => onChange(e, 'fullname')} />
        </FormGroup>
      </Col>
    </Row>

    {/* 짧은 이름 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>짧은 이름</label></Col>
          <Col className="text-right">{verificationIndicator("shortname")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={institute?.shortname}
            placeholder="해당학교의 짧은 이름을 입력해주세요. 예) UCLA"
            onChange={e => onChange(e, 'shortname')} />
        </FormGroup>
      </Col>
    </Row>

    {/* 주 전공분야 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>주 전공분야</label></Col>
        </Row>
        <FormGroup>
          <Input
            value={institute.majors}
            placeholder="콤마로 구분한 주 전공분야를 입력해주세요"
            onChange={e => onChange(e, 'majors')} />
        </FormGroup>
      </Col>
    </Row>

    {/* 프로그램 설명 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>학교 설명</label></Col>
          <Col className="text-right">{verificationIndicator("description")}</Col>
        </Row>
        <FormGroup>
          <Input
            value={institute.description}
            placeholder="고객들에게 보여지는 학교 설명을 입력해주세요"
            onChange={e => onChange(e, 'description')} />
        </FormGroup>
      </Col>
    </Row>
    {/* 지역설정 */}
    <Row>
      <Col md="6">
        <Row className="align-items-center justify-content-between">
          <Col><label>지역 1</label></Col>
          <Col className="text-right">{verificationIndicator("region1")}</Col>
        </Row>
        <FormGroup>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="region1"
            value={institute.region1}
            onChange={value => {
              // console.log('value', value);
              // console.log('institute.region1', institute.region1);
              if (value !== institute.region1) {
                setInstitute({
                  ...institute,
                  region1: _.pick(value, ['value', 'label']),
                  region2: null
                })
                // console.log('institute', institute);
              }
            }}
            options={[
              {
                value: "",
                label: "지역 1 선택",
                isDisabled: true
              },
              ...regions
            ]}
            placeholder="지역 1 선택"
          />
        </FormGroup>
      </Col>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>지역 2</label></Col>
          <Col className="text-right">{verificationIndicator("region2")}</Col>
        </Row>
        <FormGroup>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="region2"
            value={institute.region2}
            onChange={value => {
              // console.log('value', value);
              setInstitute({ ...institute, region2: _.pick(value, ['value', 'label']) })
            }}
            options={
              [
                {
                  value: "",
                  label: "지역 2 선택",
                  isDisabled: true
                },
                ...(institute.region1 ? _.find(regions, { value: institute.region1.value }).regions : [])
              ]
            }
            placeholder="지역 2 선택"
          />
        </FormGroup>
      </Col>
    </Row>

    {/* 태그 */}
    <Row>
      <Col>
        <Row className="align-items-center justify-content-between">
          <Col><label>태그(검색 키워드 매칭)</label></Col>
        </Row>
        <FormGroup>
          <Input
            value={institute.tags}
            placeholder="콤마로 구분한 태그를 입력해주세요"
            onChange={e => onChange(e, 'tags')} />
        </FormGroup>
      </Col>
    </Row>

    {/* image uploader */}
    <Row className="align-items-center">
      <h4>이미지 업로드</h4>
      <span style={{ width: 20, padding: 5 }} />
      <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addPhoto}>
        <i className="fa fa-plus" />
      </Button>
    </Row>
    {
      institute?.photos?.map((item, idx) => <Row key={idx}>
        <Col md={5}>
          <label>사진 업로드</label>
          <FormGroup>
            {
              item.url
                ? <img src={item.url} />
                : <ImageUpload onChange={({ file }) => {
                  const newPhotos = institute.photos.map((item, index) => idx === index ? { ...item, file } : item);
                  setInstitute({ ...institute, photos: newPhotos });
                }} />
            }
          </FormGroup>
        </Col>
        <Col>
          <label>이 사진에 대한 설명</label>
          <FormGroup>
            <Input
              value={item.description}
              style={{ height: "100%" }} type="textarea" placeholder="사진설명"
              onChange={event => {
                // console.log(event.target.value);
                const newPhotos = institute.photos.map((item, index) => idx === index ? { ...item, description: event.target.value } : item);
                setInstitute({ ...institute, photos: newPhotos });
              }} />
          </FormGroup>
        </Col>
        <Col md="2">
          <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deletePhoto(idx)}>
            <i className="fa fa-minus" />
          </Button>
        </Col>
      </Row>)
    }
    {/* sections */}
    <Row className="align-items-center">
      <h4>설명 섹션</h4>
      <span style={{ width: 20, padding: 5 }} />
      <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addSection}>
        <i className="fa fa-plus" />
      </Button>
    </Row>
    {
      institute?.sections?.map((item, idx) =>
        <Row key={idx} className="align-items-center" >
          <Col md={8}>
            <Row>
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col><label>제목</label></Col>
                </Row>
                <FormGroup>
                  <Input
                    value={item.title}
                    placeholder="섹션의 제목"
                    onChange={event => {
                      const newSections = institute.sections.map((item, index) => idx === index ? { ...item, title: event.target.value } : item);
                      setInstitute({ ...institute, sections: newSections });
                    }} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>내용</label>
                <FormGroup>
                  <Input
                    value={item.content}
                    style={{ height: "100%" }} type="textarea" placeholder="내용"
                    onChange={event => {
                      // console.log(event.target.value);
                      const newSections = institute.sections.map((item, index) => idx === index ? { ...item, content: event.target.value } : item);
                      setInstitute({ ...institute, sections: newSections });
                    }} />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <label>타입</label>
            <FormGroup>
              <div className="form-check-radio">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name={`contentType${idx}`}
                    id={`contentType${idx}1`}
                    value="text"
                    onChange={event => {
                      const newSections = institute.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                      setInstitute({ ...institute, sections: newSections });
                    }}
                  />
            Text
            <span className="form-check-sign"></span>
                </Label>
              </div>
              <div className="form-check-radio">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name={`contentType${idx}`}
                    id={`contentType${idx}2`}
                    value="wrappedText"
                    onChange={event => {
                      const newSections = institute.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                      setInstitute({ ...institute, sections: newSections });
                    }}
                    defaultChecked
                  />
          WrappedText
          <span className="form-check-sign"></span>
                </Label>
              </div>
              <div className="form-check-radio">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name={`contentType${idx}`}
                    id={`contentType${idx}3`}
                    value="markdown"
                    onChange={event => {
                      const newSections = institute.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                      setInstitute({ ...institute, sections: newSections });
                    }}
                  />
          Markdown
          <span className="form-check-sign"></span>
                </Label>
              </div>
            </FormGroup>
          </Col>

          <Col md="1">
            <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteSection(idx)}>
              <i className="fa fa-minus" />
            </Button>
          </Col>
        </Row>)
    }

    {/* 체크 리스트1 */}
    <Row className="align-items-center">
      <h4>체크 리스트1</h4>
      <span style={{ width: 20, padding: 5 }} />
      <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addChecklist1}>
        <i className="fa fa-plus" />
      </Button>
    </Row>
    <Row>
      <Col>
        {
          institute.checklist1?.map((item, idx) => <FormGroup key={idx}>
            <Row className="align-items-center">
              <Col md="6">
                <Input
                  value={item.title}
                  placeholder="체크할 항목"
                  onChange={e => {
                    const checklist1 = institute.checklist1.map((item, i) => i === idx ? { title: e.target.value, type: item.type } : item)
                    setInstitute({ ...institute, checklist1 })
                  }} />
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist1_file[${idx}]`}
                      name={`checklist1_file[${idx}]`}
                      type="radio"
                      value="file"
                      checked={item.type === "file"}
                      onChange={e => {
                        const checklist1 = institute.checklist1.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist1 })
                      }}
                    />
                          파일 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist1_text${idx}`}
                      name={`checklist1_text${idx}`}
                      type="radio"
                      value="text"
                      checked={item.type === "text"}
                      onChange={e => {
                        const checklist1 = institute.checklist1.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist1 })
                      }}
                    />
                          텍스트 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col md="2">
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteChecklist1Item(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
          </FormGroup>
          )
        }
      </Col>
    </Row>

    {/* 체크 리스트2 */}
    <Row className="align-items-center">
      <h4>체크 리스트2</h4>
      <span style={{ width: 20, padding: 5 }} />
      <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addChecklist2}>
        <i className="fa fa-plus" />
      </Button>
    </Row>
    <Row>
      <Col>
        {
          institute.checklist2?.map((item, idx) => <FormGroup key={idx}>
            <Row className="align-items-center">
              <Col md="6">
                <Input
                  value={item.title}
                  placeholder="체크할 항목"
                  onChange={e => {
                    const checklist2 = institute.checklist2.map((item, i) => i === idx ? { title: e.target.value, type: item.type } : item)
                    setInstitute({ ...institute, checklist2 })
                  }} />
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist2_file[${idx}]`}
                      name={`checklist2_file[${idx}]`}
                      type="radio"
                      value="file"
                      checked={item.type === "file"}
                      onChange={e => {
                        const checklist2 = institute.checklist2.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist2 })
                      }}
                    />
                          파일 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist2_text${idx}`}
                      name={`checklist2_text${idx}`}
                      type="radio"
                      value="text"
                      checked={item.type === "text"}
                      onChange={e => {
                        const checklist2 = institute.checklist2.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist2 })
                      }}
                    />
                          텍스트 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col md="2">
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteChecklist2Item(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
          </FormGroup>
          )
        }
      </Col>
    </Row>

    {/* 체크 리스트3 */}
    <Row className="align-items-center">
      <h4>체크 리스트3</h4>
      <span style={{ width: 20, padding: 5 }} />
      <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addChecklist3}>
        <i className="fa fa-plus" />
      </Button>
    </Row>
    <Row>
      <Col>
        {
          institute.checklist3?.map((item, idx) => <FormGroup key={idx}>
            <Row className="align-items-center">
              <Col md="6">
                <Input
                  value={item.title}
                  placeholder="체크할 항목"
                  onChange={e => {
                    const checklist3 = institute.checklist3.map((item, i) => i === idx ? { title: e.target.value, type: item.type } : item)
                    setInstitute({ ...institute, checklist3 })
                  }} />
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist3_file[${idx}]`}
                      name={`checklist3_file[${idx}]`}
                      type="radio"
                      value="file"
                      checked={item.type === "file"}
                      onChange={e => {
                        const checklist3 = institute.checklist3.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist3 })
                      }}
                    />
                          파일 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="form-check-radio">
                  <Label check>
                    <Input
                      id={`checklist3_text${idx}`}
                      name={`checklist3_text${idx}`}
                      type="radio"
                      value="text"
                      checked={item.type === "text"}
                      onChange={e => {
                        const checklist3 = institute.checklist3.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                        setInstitute({ ...institute, checklist3 })
                      }}
                    />
                          텍스트 <span className="form-check-sign" />
                  </Label>
                </div>
              </Col>
              <Col md="2">
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteChecklist3Item(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>
          </FormGroup>
          )
        }
      </Col>
    </Row>



    <Row>
      <Col className='text-right'>
        <Button color="primary" disabled={!isVerified || onSaving || !isModified} onClick={onSave}>저장</Button>
        <Button color="warning" onClick={onCancel}>취소</Button>
      </Col>
    </Row>
  </>


  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          {renderInstitute()}
        </Form>

      </div>
    </>
  )
}

InstituteForm.propTypes = {

}

export default InstituteForm

