import React from 'react'
import PropTypes from 'prop-types'

import Board from 'views/components/Board.jsx';
import InstituteForm from 'views/forms/InstituteForm';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';

function Institutes(props) {

  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: 'institutes' }
  ]);

  const institutes = useSelector(state => state.firestore.ordered.institutes);
  const boardEl = useRef(null);

  const data = institutes && institutes.map((item) => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`preApplications/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))

  return (
    <div className="content">
      <Board
        title="교육기관"
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: "Fullname",
            accessor: "fullname"
          },
          {
            Header: "Shortname",
            accessor: "shortname"
          },
          {
            id: 'region1',
            Header: "Region1",
            accessor: d => d.region1.label
          },
          {
            id: 'region2',
            Header: "Region2",
            accessor: d => d.region2.label
          },
          {
            Header: "Tags",
            accessor: "tags"
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <InstituteForm />
      </Board>
    </div>
  )
}

Institutes.propTypes = {

}

export default Institutes

