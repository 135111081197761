import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import Switch from "react-bootstrap-switch";
import DropzoneUploader from 'components/CustomUpload/DropzoneUploader';

import { profApplicationStatus } from '../../variables/constants';

const initApplication = {
  checklist1: [],
  checklist2: [],
  checklist3: [],
};

function ApplicationCheckForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [application, setApplication] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initApplication));

  console.log('currentItem = ', props.currentItem);

  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);

  const [filesPath, setFilesPath] = useState(`applications/${props.currentItem.id}`);

  useEffect(() => {
    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('applications').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //check modification
  useEffect(() => {
    setIsModified(props.currentItem === null
      ? !_.isEqual(initApplication, application)
      : !_.isEqual(props.currentItem, application)
    );
  }, [application]);

  useEffect(() => {
    if (fileChanged) {
      doSave();
    }
    return () => {
      // cleanup
    }
  }, [fileChanged])

  const onSave = async () => {
    console.log('press save');

    if (!isModified) {
      basicAlert('변경된 값이 없습니다.');
      return;
    }
    await doSave();
    props.toggle()
  }

  const doSave = async () => {
    setOnSaving(true);

    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }

    try {
      console.log('let us save!');
      await firestore
        .collection('applications')
        .doc(docId)
        .set({ ...application, ...timeStamps });
    } catch (e) {
      if (e.code === "permission-denied") {
        basicAlert('프로그램 작성 권한이 없습니다');
      }
      console.error('failed to save: ', e);
    } finally {
      setOnSaving(false);
    }

    setFileChanged(false);
    return;
  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const onFilesDrop = (files) => {
    return firebase.uploadFiles(filesPath, files, filesPath);
  }

  const onFileDelete = (file, key) => {
    return firebase.deleteFile(file.fullPath, `${filesPath}/${key}`);
  }

  return (
    <div className="content">
      <Form action="#" method="#">
        <Row>
          <h4>체크 리스트 1</h4>
        </Row>
        {
          application.program.checklist1.map((item, idx) => <Row className='align-items-center justify-content-between'
            key={`checklist1${idx}`}
          >
            <Col className='align-item-center'>
              <h6>{item.title}</h6>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check>
                  <Input
                    defaultChecked={item.selfCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist1 = application.program.checklist1
                        .map((item, i) => i === idx
                          ? { ...item, selfCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist1
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  고객 셀프 체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check >
                  <Input
                    defaultChecked={item.aaCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist1 = application.program.checklist1
                        .map((item, i) => i === idx
                          ? { ...item, aaCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist1
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  AA체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <DropzoneUploader
                filesPath={filesPath}
                files={item.files}
                onComplete={(files) => {
                  const checklist1 = application.program.checklist1
                    .map((item, i) => i === idx
                      ? { ...item, files }
                      : item);
                  setApplication(
                    {
                      ...application,
                      program: {
                        ...application.program,
                        checklist1
                      }
                    });
                  setFileChanged(true);
                }} />
            </Col>
          </Row>
          )
        }
        <Row>
          <h4>체크 리스트 2</h4>
        </Row>
        {
          application.program.checklist2.map((item, idx) => <Row className='align-items-center justify-content-between'
            key={`checklist2${idx}`}
          >
            <Col className='align-item-center'>
              <h6>{item.title}</h6>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check>
                  <Input
                    defaultChecked={item.selfCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist2 = application.program.checklist2
                        .map((item, i) => i === idx
                          ? { ...item, selfCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist2
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  고객 셀프 체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check >
                  <Input
                    defaultChecked={item.aaCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist2 = application.program.checklist2
                        .map((item, i) => i === idx
                          ? { ...item, aaCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist2
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  AA체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <DropzoneUploader
                filesPath={filesPath}
                files={item.files}
                onComplete={(files) => {
                  const checklist2 = application.program.checklist2
                    .map((item, i) => i === idx
                      ? { ...item, files }
                      : item);
                  setApplication(
                    {
                      ...application,
                      program: {
                        ...application.program,
                        checklist2
                      }
                    });
                  setFileChanged(true);
                }} />

            </Col>
          </Row>
          )
        }
        <Row>
          <h4>체크 리스트 3</h4>
        </Row>
        {
          application.program.checklist3.map((item, idx) => <Row className='align-items-center justify-content-between'
            key={`checklist3${idx}`}
          >
            <Col className='align-item-center'>
              <h6>{item.title}</h6>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check>
                  <Input
                    defaultChecked={item.selfCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist3 = application.program.checklist3
                        .map((item, i) => i === idx
                          ? { ...item, selfCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist3
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  고객 셀프 체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <FormGroup check>
                <Label check >
                  <Input
                    defaultChecked={item.aaCheck || false}
                    type="checkbox"
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked)
                      const checklist3 = application.program.checklist3
                        .map((item, i) => i === idx
                          ? { ...item, aaCheck: e.target.checked }
                          : item);
                      setApplication(
                        {
                          ...application,
                          program: {
                            ...application.program,
                            checklist3
                          }
                        });
                    }}
                  />
                  <span className="form-check-sign" />
                  AA체크
                </Label>
              </FormGroup>
            </Col>
            <Col className='align-item-center'>
              <DropzoneUploader
                filesPath={filesPath}
                files={item.files}
                onComplete={(files) => {
                  const checklist3 = application.program.checklist3
                    .map((item, i) => i === idx
                      ? { ...item, files }
                      : item);
                  setApplication(
                    {
                      ...application,
                      program: {
                        ...application.program,
                        checklist3
                      }
                    });
                  setFileChanged(true);
                }} />
            </Col>
          </Row>
          )
        }
        <Row>
          <h4>처리 상태</h4>
        </Row>
        <FormGroup>
          {
            profApplicationStatus.map((item, idx) =>
              <div key={idx} className="form-check-radio">
                <Label className="form-check-label">
                  <Input
                    type="radio"
                    name={`applicationStatus${idx}`}
                    id={`applicationStatus${idx}`}
                    value="text"
                    checked={idx === application.status}
                    onChange={event => {
                      setApplication({ ...application, status: idx });
                    }}
                  />
                  {item}
                  <span className="form-check-sign"></span>
                </Label>
              </div>
            )
          }
        </FormGroup>
        <Row>
          <Col className='text-right'>
            <Button color="primary" disabled={onSaving || !isModified} onClick={onSave}>저장</Button>
            <Button color="warning" onClick={onCancel}>취소</Button>
          </Col>
        </Row>

      </Form>
    </div>
  )
}

export default ApplicationCheckForm
