import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFirestoreConnect } from 'react-redux-firebase';

import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  getDefaultKeyBinding,
  Modifier,
} from 'draft-js';

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from 'variables/constants';
import { regions } from 'variables/constants';
import { schoolAge } from 'variables/constants';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { isValidPhoneNumber } from 'helpers/validation';
import { isValidEmailAddress } from 'helpers/validation';


const initPost = {
  schedules: [],
  checklist: [],
  photos: [], // {file, description}
};

function BBSForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [post, setPost] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initPost));
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    title: { isValid: false, message: null },
    content: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [editorState, setEditorState] = React.useState(
    props.currentItem && props.currentItem.editorState
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.currentItem.editorState)))
      : EditorState.createEmpty()
  );

  const [className, setClassName] = React.useState('RichEditor-editor');
  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  useEffect(() => {
    focusEditor();

    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('posts').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //verification
  useEffect(() => {
    const {
      title,
      content,
      photos
    } = post;
    setVerification({
      title: { isValid: title && title.length > 1, message: '두 글자 이상 입력해주세요' },
      content: { isValid: content && content.replace(/\s/g, '').length > 1, message: '두 글자 이상 입력해주세요' },
      // photos: { isValid: photos && photos.filter(photo => (photo.file || photo.url) && photo.description).length > 0, message: '최소 이미지 하나는 추가해주세요' }
    });

    setIsModified(props.currentItem === null
      ? !_.isEqual(initPost, post)
      : !_.isEqual(props.currentItem, post)
    );

  }, [post]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(verification, (value, key) => value.isValid !== true);
    console.log('invalidItems', invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification])

  useEffect(() => {
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    setClassName(className);

  }, [editorState])

  const verificationIndicator = (id) => {
    // console.log("verification[id].isValid", verification[`${id}`].isValid);
    if (verification[`${id}`].isValid) {
      return <i className="nc-icon nc-check-2" style={{ color: 'lightgreen', fontSize: '1.5em' }} />
    } else {
      return <><i className="nc-icon nc-alert-circle-i" style={{ color: 'red', fontSize: '1.5em' }} id={`${id}_invalid`} />
        <UncontrolledTooltip target={`${id}_invalid`}>{verification[`${id}`].message}</UncontrolledTooltip>
      </>
    }
  }


  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChangeEditorState(newState);
      return true;
    }
    return false;
  }

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4 /* maxDepth */,
      );
      if (newEditorState !== editorState) {
        onChangeEditorState(newEditorState);
      }
      return false;
    }
    return getDefaultKeyBinding(e);
  }

  const toggleBlockType = (blockType) => {
    onChangeEditorState(
      RichUtils.toggleBlockType(editorState, blockType),
    );
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChangeEditorState(
      RichUtils.toggleInlineStyle(editorState, inlineStyle),
    );
  }


  const onChangeEditorState = editorState => {
    console.log(editorState);
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    console.log('value', value);
    setEditorState(editorState);
    return setPost({ ...post, content: value });
  }


  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setPost({ ...post, [name]: typeFn ? typeFn(value) : value });
  }

  const onSave = async () => {
    console.log('press save');

    if (!isVerified) {
      basicAlert('값을 확인해주세요');
      return;
    }

    const editorStateToArchive = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    setOnSaving(true);

    const { photos } = post;
    const path = `users/${docId}`;
    // console.log('photos', photos);
    const needUploadFiles = photos.filter(item => item.file);
    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }

    if (needUploadFiles.length > 0) {
      const alreadyUploadedFiles = photos.filter(item => item.url);
      return firebase.uploadFiles(path, needUploadFiles.map(photo => photo.file))
        .then((uploadingInfo) => {
          // console.log('uploading info', uploadingInfo);
          return Promise.all(uploadingInfo.map(({ uploadTaskSnapshot }) => uploadTaskSnapshot.ref.getDownloadURL()))
        })
        .then(urls => {
          // console.log('urls', urls);
          const newlyUploadedPhotos = needUploadFiles.map((item, index) => ({ url: urls[index], description: item.description }));
          const newPhotos = [...alreadyUploadedFiles, ...newlyUploadedPhotos];
          // console.log('newPhotos', newPhotos);

          setPost({ ...post, photos: newPhotos }); // this dose not change program immediately
          // I just wrote this line for in case this modal is not closed.

          return firestore
            .collection('posts')
            .doc(docId)
            .set({ ...post, photos: newPhotos, ...timeStamps, editorState: editorStateToArchive }, { merge: true })
            .then(() => props.toggle())
            .catch(e => {
              if (e.code === "permission-denied") {
                basicAlert('생성 권한이 없습니다');
              }
              setOnSaving(false);
            })
        })
        .catch(err => {
          console.error('error uploading files', err)
          setOnSaving(false);
          basicAlert('유저 생성에 실패했습니다.');
        })
    } else {
      return firestore
        .collection('posts')
        .doc(docId)
        .set({ ...post, photos: [], ...timeStamps, editorState: editorStateToArchive }, { merge: true })
        .then(() => props.toggle())
        .catch(e => {
          if (e.code === "permission-denied") {
            basicAlert('생성 권한이 없습니다');
          }
          setOnSaving(false);
        })
    }

  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const addPhoto = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url: 'http://wwww.naver.com'
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey,
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithLink,
    );
    setEditorState(newEditorState);

    // setPost({ ...post, photos: [...post.photos, { file: null, description: '' }] });
  }

  const deletePhoto = (idx) => {
    const newPhotos = post.photos.filter((item, i) => i !== idx);
    setPost({ ...post, photos: newPhotos });
  }

  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          {/* 글 제목 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>글 제목</label></Col>
                <Col className="text-right">{verificationIndicator("title")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={post.title}
                  placeholder="글 제목을 입력해주세요"
                  onChange={e => onChange(e, 'title')} />
              </FormGroup>
            </Col>
          </Row>
          <Row onClick={focusEditor}>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>글 내용</label></Col>
                <Col className="text-right">{verificationIndicator("content")}</Col>
              </Row>
              <FormGroup>
                {/* <Editor
                  ref={editor}
                  editorState={editorState}
                  handleKeyCommand={handleKeyCommand}
                  onChange={onChangeEditorState}
                /> */}

                <div className="RichEditor-root">
                  <div className="RichEditor-controls-container">
                    <BlockStyleControls
                      editorState={editorState}
                      onToggle={toggleBlockType}
                    />
                    <InlineStyleControls
                      editorState={editorState}
                      onToggle={toggleInlineStyle}
                    />
                  </div>
                  <div className={className} onClick={focusEditor}>
                    <Editor
                      blockStyleFn={getBlockStyle}
                      customStyleMap={styleMap}
                      editorState={editorState}
                      handleKeyCommand={handleKeyCommand}
                      keyBindingFn={mapKeyToEditorCommand}
                      onChange={onChangeEditorState}
                      ref={editor}
                      spellCheck={true}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col className='text-right'>
              <Button color="primary" disabled={!isVerified || onSaving || !isModified} onClick={onSave}>저장</Button>
              <Button color="warning" onClick={onCancel}>취소</Button>
              <Button color="warning" onClick={addPhoto}>add photo</Button>
            </Col>
          </Row>

        </Form>

      </div>
    </>
  )
}

BBSForm.propTypes = {

}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'img-block':
      return 'ImageFancyBlock';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
  { label: 'IMG', style: 'img-block' }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};


export default BBSForm

