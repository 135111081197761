import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import _ from 'lodash';

import {
  Button
} from 'reactstrap';

function DropzoneUploader({ filesPath, files = [], onComplete }) {
  const firebase = useFirebase();
  const uploadedFiles = useSelector(({ firebase: { data } }) => data[filesPath]);

  const [myFiles, setMyFiles] = useState(files)

  // function onFilesDrop(files) {
  // }
  // function onFileDelete(file, key) {
  //   return firebase.deleteFile(file.fullPath, `${filesPath}/${key}`);
  // }

  useEffect(() => {
    onComplete(myFiles);
    return () => {
      // cleanup
    }
  }, [myFiles]);

  const onDrop = useCallback(async acceptedFiles => {
    console.log('file: ', acceptedFiles);
    try {
      const uploadingInfo = await firebase.uploadFiles(filesPath, acceptedFiles, undefined, { name: (file) => `${+new Date()}${file.name}` });
      const urls = await Promise.all(uploadingInfo.map(({ uploadTaskSnapshot }) => uploadTaskSnapshot.ref.getDownloadURL()));
      const addedFiles = uploadingInfo.map(({ uploadTaskSnapshot: { metadata } }, idx) => {
        console.log('metadata: ', metadata);
        return {
          path: metadata.name,
          size: metadata.size,
          url: urls[idx]
        };
      });
      console.log('addedFiles: ', addedFiles);
      setMyFiles([...myFiles, ...addedFiles]);
    } catch (error) {
      console.error('failed to upload file ', error);
    }

  }, [])

  const removeFile = file => () => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
    console.log('file: ', file);
    firebase.deleteFile(`${filesPath}/${file.path}`);
  }

  const removeAll = async () => {
    try {
      await Promise.all(myFiles.map(file => firebase.deleteFile(`${filesPath}/${file.path}`)));
    } catch (error) {
      console.error('failed to remove files: ', error);
    }
    setMyFiles([]);
  }

  const renderFiles = myFiles.map(file => (
    <li key={file.path}>
      <a href={file.url} target='_new'>
        {file.path} - {file.size} bytes{" "}
      </a>
      <Button onClick={removeFile(file)} className="btn-link" color="primary">삭제</Button>
    </li>
  ))

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>여기 파일 올리세요.</p> :
            <p>파일을 드래그 드롭 하세요. 혹은 눌러서 파일을 선택하세요.</p>
        }
      </div>
      <aside>
        <h6>Files</h6>
        <ul>{renderFiles}</ul>
      </aside>
      {myFiles.length > 0 && <Button color="primary" onClick={removeAll}>{myFiles.length}개 파일 지우기</Button>}
    </>
  )
}

export default DropzoneUploader;
