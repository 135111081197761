/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import firebase from 'firebase/app';

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Provider } from 'react-redux';

import configureStore from "./store";

const hist = createBrowserHistory();
const initialState = {};
const store = configureStore(initialState, hist);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  enableClaims: true,
  useFirestoreForProfile: true
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

function App() {
  firebase.auth().onAuthStateChanged(authUser => {
    if (!authUser)
      hist.push('/auth/login');
  });

  return <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router history={hist}>
        <Switch>
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Route path="/admin" render={props => <AdminLayout {...props} />} />
          <Redirect to="/auth/login" />
        </Switch>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
