import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFirestoreConnect } from 'react-redux-firebase';

import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from 'variables/constants';
import { regions } from 'variables/constants';
import { schoolAge } from 'variables/constants';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';


const initProgram = {
  schedules: [],
  checklist: [],
  photos: [], // {file, description}
  sections: [], // title, content, contentType [wrappedText, markdown, text]
};

function ProgramForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [program, setProgram] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initProgram));
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    title: { isValid: false, message: null },
    description: { isValid: false, message: null },
    sections: { isValid: false, message: null },
    category1: { isValid: false, message: null },
    category2: { isValid: false, message: null },
    region1: { isValid: false, message: null },
    region2: { isValid: false, message: null },
    institute: { isValid: false, message: null },
    checkList: { isValid: false, message: null },
    schedules: { isValid: false, message: null },
    photos: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('programs').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //verification
  useEffect(() => {
    const {
      title,
      description,
      sections,
      category1,
      category2,
      region1,
      region2,
      institute,
      checkList,
      schedules,
      photos,
      isRecommandation,
      recommandationPriority,
      isDraft,
    } = program;
    setVerification({
      title: { isValid: title && title.length > 3, message: '4글자 이상 입력해주세요' },
      description: { isValid: description && description.length > 5, message: '6글자 이상 입력해주세요' },
      sections: { isValid: sections && sections.length > 0, message: '최소 하나 이상의 섹션을 추가해주세요.' },
      category1: { isValid: Boolean(category1), message: '카테고리 1 선택해주세요' },
      category2: { isValid: Boolean(category2), message: '카테고리 2 선택해주세요' },
      region1: { isValid: Boolean(region1), message: '지역 1 선택해주세요' },
      region2: { isValid: Boolean(region2), message: '지역 2 선택해주세요' },
      institute: { isValid: Boolean(institute), message: '학교를 선택해주세요' },
      photos: { isValid: photos && photos.length > 0, message: '최소 이미지 하나는 추가해주세요' }
    });

    setIsModified(props.currentItem === null
      ? !_.isEqual(initProgram, program)
      : !_.isEqual(props.currentItem, program)
    );

  }, [program]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(verification, (value, key) => value.isValid !== true);
    console.log('invalidItems', invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification])

  const verificationIndicator = (id) => {
    // console.log("verification[id].isValid", verification[`${id}`].isValid);
    if (verification[`${id}`].isValid) {
      return <i className="nc-icon nc-check-2" style={{ color: 'lightgreen', fontSize: '1.5em' }} />
    } else {
      return <><i className="nc-icon nc-alert-circle-i" style={{ color: 'red', fontSize: '1.5em' }} id={`${id}_invalid`} />
        <UncontrolledTooltip target={`${id}_invalid`}>{verification[`${id}`].message}</UncontrolledTooltip>
      </>
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setProgram({ ...program, [name]: typeFn ? typeFn(value) : value });
    // verify();
  }

  const onSave = async () => {
    console.log('press save');

    if (!isVerified) {
      basicAlert('값을 확인해주세요');
      return;
    }

    setOnSaving(true);

    const { photos } = program;
    const path = `programs/${docId}`;
    // console.log('photos', photos);
    const needUploadFiles = photos.filter(item => item.file);
    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }
    if (needUploadFiles.length > 0) {
      const alreadyUploadedFiles = photos.filter(item => item.url);
      return firebase.uploadFiles(path, needUploadFiles.map(photo => photo.file))
        .then((uploadingInfo) => {
          // console.log('uploading info', uploadingInfo);
          return Promise.all(uploadingInfo.map(({ uploadTaskSnapshot }) => uploadTaskSnapshot.ref.getDownloadURL()))
        })
        .then(urls => {
          // console.log('urls', urls);
          const newlyUploadedPhotos = needUploadFiles.map((item, index) => ({ url: urls[index], description: item.description }));
          const newPhotos = [...alreadyUploadedFiles, ...newlyUploadedPhotos];
          // console.log('newPhotos', newPhotos);

          setProgram({ ...program, photos: newPhotos }); // this dose not change program immediately
          // I just wrote this line for in case this modal is not closed.

          return firestore
            .collection('programs')
            .doc(docId)
            .set({ ...program, photos: newPhotos, ...timeStamps }, { merge: true })
            .then(() => props.toggle())
            .catch(e => {
              if (e.code === "permission-denied") {
                basicAlert('프로그램 작성 권한이 없습니다');
              }
              setOnSaving(false);
            })
        })
        .catch(err => {
          console.error('error uploading files', err)
          setOnSaving(false);
          basicAlert('프로그램 작성에 실패했습니다.');
        })
    } else {
      return firestore
        .collection('programs')
        .doc(docId)
        .set({ ...program, ...timeStamps }, { merge: true })
        .then(() => props.toggle())
        .catch(e => {
          if (e.code === "permission-denied") {
            basicAlert('프로그램 작성 권한이 없습니다');
          }
          setOnSaving(false);
        })
    }

  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }


  const addSchedule = () => {
    setProgram({ ...program, schedules: [...program.schedules, { date: undefined, event: '' }] });
  }

  const deleteSchedule = (idx) => {
    // console.log('idx', idx);
    const newSchedule = program.schedules.filter((item, i) => i !== idx);
    // console.log('newSchedule', newSchedule);
    setProgram({ ...program, schedules: newSchedule });
  }

  const addCheckList = () => {
    setProgram({ ...program, checklist: [...program.checklist, { title: '', type: undefined }] });
  }

  const deleteChecklistItem = (idx) => {
    // console.log('idx', idx);
    const newChecklist = program.checklist.filter((item, i) => i !== idx);
    // console.log('newChecklist', newChecklist);
    setProgram({ ...program, checklist: newChecklist });
  }

  const addPhoto = () => {
    setProgram({ ...program, photos: [...program.photos, { file: null, description: '' }] });
  }

  const deletePhoto = (idx) => {
    const newPhotos = program.photos.filter((item, i) => i !== idx);
    setProgram({ ...program, photos: newPhotos });
  }

  const addSection = () => {
    setProgram({ ...program, sections: [...program.sections, { content: undefined, contentType: 'wrappedText', title: undefined }] });
  }

  const deleteSection = (idx) => {
    // console.log('idx', idx);
    const newSections = program.sections.filter((item, i) => i !== idx);
    setProgram({ ...program, sections: newSections });
  }

  const defaultCheckList = (category1) => {
    const { value } = category1;
    switch (value) {
      case "1": // 단기스쿨링
        return [
          { title: "여권사본", type: "file" },
          { title: "개인정보(학교, 학년)", type: "text" }
        ];

      case "2": // 전문직
        return [
          { title: "이력서", type: "file" },
          { title: "연구 계획서", type: "file" },
          { title: "여권 사본", type: "file" },
          { title: "추천서", type: "file" },
          { title: "학교별 지원서", type: "file" },
          { title: "재정보증 및 증명서", type: "file" },
          { title: "가족여권사본", type: "file" },
        ];

      case "3": // 조기유학(초,중,고)
      case "4": // 대학(원) 성인유학
        return [];

      default:
        return [];
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          <Row>
            <h4>일반 정보</h4>
          </Row>
          {/* 프로그램 명칭 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>프로그램 명칭</label></Col>
                <Col className="text-right">{verificationIndicator("title")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={program.title}
                  placeholder="고객들에게 보여지는 프로그램 이름을 입력해주세요"
                  onChange={e => onChange(e, 'title')} />
              </FormGroup>
            </Col>
          </Row>
          {/* 프로그램 설명 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>프로그램 설명</label></Col>
                <Col className="text-right">{verificationIndicator("description")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={program.description}
                  placeholder="고객들에게 보여지는 프로그램 설명을 입력해주세요"
                  onChange={e => onChange(e, 'description')} />
              </FormGroup>
            </Col>
          </Row>
          {/* 카테고리 설정 */}
          <Row>
            <Col md="6">
              <Row className="align-items-center justify-content-between">
                <Col><label>카테고리 1</label></Col>
                <Col className="text-right">{verificationIndicator("category1")}</Col>
              </Row>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="category1"
                  value={program.category1}
                  onChange={value => {
                    // console.log('value', value);
                    // console.log('program.category1', program.category1);
                    if (value !== program.category1) {
                      setProgram({
                        ...program,
                        category1: _.pick(value, ['value', 'label']),
                        category2: null,
                        checklist: defaultCheckList(value)
                      })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "카테고리 1 선택",
                      isDisabled: true
                    },
                    ...categories
                  ]}
                  placeholder="카테고리 1 선택"
                />
              </FormGroup>
            </Col>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>카테고리 2</label></Col>
                <Col className="text-right">{verificationIndicator("category2")}</Col>
              </Row>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="category2"
                  value={program.category2}
                  onChange={value => {
                    // console.log('value', value);
                    setProgram({
                      ...program,
                      category2: _.pick(value, ['value', 'label'])
                    })
                  }}
                  options={
                    [
                      {
                        value: "",
                        label: "카테고리 2 선택",
                        isDisabled: true
                      },
                      ...(program.category1 ? _.find(categories, { value: program.category1.value }).categories : [])
                    ]
                  }
                  placeholder="카테고리 2 선택"
                />
              </FormGroup>
            </Col>
          </Row>
          {/* 지역설정 */}
          <Row>
            <Col md="6">
              <Row className="align-items-center justify-content-between">
                <Col><label>지역 1</label></Col>
                <Col className="text-right">{verificationIndicator("region1")}</Col>
              </Row>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="region1"
                  value={program.region1}
                  onChange={value => {
                    // console.log('value', value);
                    // console.log('program.region1', program.region1);
                    if (value !== program.region1) {
                      setProgram({
                        ...program,
                        region1: _.pick(value, ['value', 'label']),
                        region2: null
                      })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "지역 1 선택",
                      isDisabled: true
                    },
                    ...regions
                  ]}
                  placeholder="지역 1 선택"
                />
              </FormGroup>
            </Col>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>지역 2</label></Col>
                <Col className="text-right">{verificationIndicator("region2")}</Col>
              </Row>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="region2"
                  value={program.region2}
                  onChange={value => {
                    // console.log('value', value);
                    setProgram({ ...program, region2: _.pick(value, ['value', 'label']) })
                  }}
                  options={
                    [
                      {
                        value: "",
                        label: "지역 2 선택",
                        isDisabled: true
                      },
                      ...(program.region1 ? _.find(regions, { value: program.region1.value }).regions : [])
                    ]
                  }
                  placeholder="지역 2 선택"
                />
              </FormGroup>
            </Col>

          </Row>

          {/* 학교 선정 */}
          <Row>
            <Col md="6">
              <Row className="align-items-center justify-content-between">
                <Col><label>학교 선정</label></Col>
                <Col className="text-right">{verificationIndicator("institute")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={program.institute}
                  placeholder="학교 이름"
                  onChange={e => onChange(e, 'institute')} />
              </FormGroup>

              {/* <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="institute"
                  value={program.institute}
                  onChange={value => {
                    // console.log('value', value);
                    // console.log('program.institute', program.institute);
                    if (value !== program.institute) {
                      setProgram({ ...program, institute: value })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "학교 선택",
                      isDisabled: true
                    },
                    { value: "1", label: "미국" },
                    { value: "2", label: "캐나다" },
                    { value: "3", label: "동남아시아" },
                  ]}
                  placeholder="학교 선택"
                />
              </FormGroup> */}
            </Col>
          </Row>
          {/* 체크 리스트 */}
          <Row className="align-items-center">
            <h4>체크 리스트</h4>
            <span style={{ width: 20, padding: 5 }} />
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addCheckList}>
              <i className="fa fa-plus" />
            </Button>
          </Row>
          <Row>
            <Col>
              {
                program.checklist.map((item, idx) => <FormGroup key={idx}>
                  <Row className="align-items-center">
                    <Col md="6">
                      <Input
                        value={item.title}
                        placeholder="체크할 항목"
                        onChange={e => {
                          const checklist = program.checklist.map((item, i) => i === idx ? { title: e.target.value, type: item.type } : item)
                          setProgram({ ...program, checklist })
                        }} />
                    </Col>
                    <Col>
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            id={`file[${idx}]`}
                            name={`file[${idx}]`}
                            type="radio"
                            value="file"
                            checked={item.type === "file"}
                            onChange={e => {
                              const checklist = program.checklist.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                              setProgram({ ...program, checklist })
                            }}
                          />
                          파일 <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            id={`text${idx}`}
                            name={`text${idx}`}
                            type="radio"
                            value="text"
                            checked={item.type === "text"}
                            onChange={e => {
                              const checklist = program.checklist.map((item, i) => i === idx ? { title: item.title, type: e.target.value } : item)
                              setProgram({ ...program, checklist })
                            }}
                          />
                          텍스트 <span className="form-check-sign" />
                        </Label>
                      </div>
                    </Col>
                    <Col md="2">
                      <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteChecklistItem(idx)}>
                        <i className="fa fa-minus" />
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                )
              }
            </Col>
          </Row>
          <Row className="align-items-center">
            <h4>일정/스케줄</h4>
            <span style={{ width: 20, padding: 5 }} />
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addSchedule}>
              <i className="fa fa-plus" />
            </Button>
          </Row>
          {/* 일정 설정 */}
          <Row>
            <Col>
              {
                program.schedules.map((item, idx) => <FormGroup key={idx}>
                  <Row className="align-items-center">
                    <Col md="4">
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "일정",
                        }}
                        value={item.date}
                        locale={'ko'}
                        dateFormat={"YYYY-MM-DD"}
                        timeFormat={false}
                        onChange={(date) => {
                          const schedules = program.schedules.map((item, i) => i === idx ? { date: date.format('YYYY-MM-DD'), event: item.event } : item)
                          setProgram({ ...program, schedules })
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        value={item.event}
                        placeholder="이벤트 이름" onChange={e => {
                          const schedules = program.schedules.map((item, i) => i === idx ? { date: item.date, event: e.target.value } : item)
                          setProgram({ ...program, schedules })
                        }} />
                    </Col>
                    <Col md="2">
                      <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteSchedule(idx)}>
                        <i className="fa fa-minus" />
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                )
              }
            </Col>
          </Row>

          {/* image uploader */}
          <Row className="align-items-center">
            <h4>이미지 업로드</h4>
            <span style={{ width: 20, padding: 5 }} />
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addPhoto}>
              <i className="fa fa-plus" />
            </Button>
          </Row>
          {
            program.photos.map((item, idx) => <Row key={idx}>
              <Col md={5}>
                <label>사진 업로드</label>
                <FormGroup>
                  {
                    item.url
                      ? <img src={item.url} />
                      : <ImageUpload onChange={({ file }) => {
                        const newPhotos = program.photos.map((item, index) => idx === index ? { ...item, file } : item);
                        setProgram({ ...program, photos: newPhotos });
                      }} />
                  }
                </FormGroup>
              </Col>
              <Col>
                <label>이 사진에 대한 설명</label>
                <FormGroup>
                  <Input
                    value={item.description}
                    style={{ height: "100%" }} type="textarea" placeholder="사진설명"
                    onChange={event => {
                      // console.log(event.target.value);
                      const newPhotos = program.photos.map((item, index) => idx === index ? { ...item, description: event.target.value } : item);
                      setProgram({ ...program, photos: newPhotos });
                    }} />
                </FormGroup>
              </Col>
              <Col md="2">
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deletePhoto(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>)
          }

          {/* sections */}
          <Row className="align-items-center">
            <h4>설명 섹션</h4>
            <span style={{ width: 20, padding: 5 }} />
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addSection}>
              <i className="fa fa-plus" />
            </Button>
          </Row>
          {
            program?.sections?.map((item, idx) =>
              <Row key={idx} className="align-items-center" >
                <Col md={8}>
                  <Row>
                    <Col>
                      <Row className="align-items-center justify-content-between">
                        <Col><label>제목</label></Col>
                      </Row>
                      <FormGroup>
                        <Input
                          value={item.title}
                          placeholder="섹션의 제목"
                          onChange={event => {
                            const newSections = program.sections.map((item, index) => idx === index ? { ...item, title: event.target.value } : item);
                            setProgram({ ...program, sections: newSections });
                          }} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>내용</label>
                      <FormGroup>
                        <Input
                          value={item.content}
                          style={{ height: "100%" }} type="textarea" placeholder="내용"
                          onChange={event => {
                            // console.log(event.target.value);
                            const newSections = program.sections.map((item, index) => idx === index ? { ...item, content: event.target.value } : item);
                            setProgram({ ...program, sections: newSections });
                          }} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <label>타입</label>
                  <FormGroup>
                    <div className="form-check-radio">
                      <Label className="form-check-label">
                        <Input
                          type="radio"
                          name={`contentType${idx}`}
                          id={`contentType${idx}1`}
                          value="text"
                          checked={item.contentType === 'text'}
                          onChange={event => {
                            const newSections = program.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                            setProgram({ ...program, sections: newSections });
                          }}
                        />
            Text
            <span className="form-check-sign"></span>
                      </Label>
                    </div>
                    <div className="form-check-radio">
                      <Label className="form-check-label">
                        <Input
                          type="radio"
                          name={`contentType${idx}`}
                          id={`contentType${idx}2`}
                          value="wrappedText"
                          checked={item.contentType === 'wrappedText'}
                          onChange={event => {
                            const newSections = program.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                            setProgram({ ...program, sections: newSections });
                          }}
                        />
          WrappedText
          <span className="form-check-sign"></span>
                      </Label>
                    </div>
                    <div className="form-check-radio">
                      <Label className="form-check-label">
                        <Input
                          type="radio"
                          name={`contentType${idx}`}
                          id={`contentType${idx}3`}
                          value="markdown"
                          checked={item.contentType === 'markdown'}
                          onChange={event => {
                            const newSections = program.sections.map((item, index) => idx === index ? { ...item, contentType: event.target.value } : item);
                            setProgram({ ...program, sections: newSections });
                          }}
                        />
          Markdown
          <span className="form-check-sign"></span>
                      </Label>
                    </div>
                  </FormGroup>
                </Col>

                <Col md="1">
                  <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deleteSection(idx)}>
                    <i className="fa fa-minus" />
                  </Button>
                </Col>
              </Row>)
          }


          <Row className="align-items-center">
            <h4>기간 설정</h4>
          </Row>
          {/* 상세정보 - 기간 */}
          <Row className="align-items-center">
            <Col>
              <label>시작일</label>
              <ReactDatetime
                value={program.startAt}
                inputProps={{
                  className: "form-control",
                  placeholder: "시작일",
                }}
                locale={'ko'}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                onChange={(date) => {
                  setProgram({ ...program, startAt: date.format('YYYY-MM-DD') })
                }}
              />
            </Col>
            <Col>
              <label>종료일</label>
              <ReactDatetime
                value={program.endAt}
                inputProps={{
                  className: "form-control",
                  placeholder: "종료일",
                }}
                locale={'ko'}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                onChange={(date) => {
                  setProgram({ ...program, endAt: date.format('YYYY-MM-DD') })
                }}
              />
            </Col>

          </Row>

          <Row className="align-items-center">
            <h4>대상 설정</h4>
          </Row>
          {/* 대상학년 */}
          <Row className="align-items-center">
            <Col md="3">
              <label>대상 학년(최저)</label>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="schoolAgeFrom"
                  value={program.schoolAgeFrom}
                  onChange={value => {
                    // console.log('value', value);
                    // console.log('program.schoolAge', program.schoolAge);
                    if (value !== program.schoolAgeFrom) {
                      setProgram({ ...program, schoolAgeFrom: value })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "대상 학년",
                      isDisabled: true
                    },
                    ...schoolAge
                  ]}
                  placeholder="학년 선택"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <label>대상 학년(최고)</label>
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="schoolAgeTo"
                  value={program.schoolAgeTo}
                  onChange={value => {
                    // console.log('value', value);
                    // console.log('program.schoolAge', program.schoolAge);
                    if (value !== program.schoolAgeTo) {
                      setProgram({ ...program, schoolAgeTo: value })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "대상 학년",
                      isDisabled: true
                    },
                    ...schoolAge
                  ]}
                  placeholder="학년 선택"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <label>정원</label>
              <FormGroup>
                <Input
                  value={program.quota}
                  placeholder="정원" inputprops={{ type: 'number', min: '1', max: '100' }} onChange={e => onChange(e, 'quota', parseInt)} />
              </FormGroup>
            </Col>
            <Col md="4">
              <label>대상 설명</label>
              <FormGroup>
                <Input
                  value={program.targetDescription}
                  placeholder="예) 초등학생 자녀 & 학부모" onChange={e => onChange(e, 'targetDescription')} />
              </FormGroup>
            </Col>
          </Row>
          <Row className="align-items-center">
            <h4>가격 설정</h4>
          </Row>
          {/* 가격 */}
          <Row className="align-items-center">
            <Col md="6">
              <label>가격조건</label>
              <FormGroup>
                <Input
                  value={program.priceCondition}
                  placeholder="가격조건 예)14박 • 1인기준" inputprops={{ type: 'string' }} onChange={e => onChange(e, 'priceCondition')} />
              </FormGroup>
            </Col>
            <Col md="3">
              <label>가격</label>
              <FormGroup>
                <Input
                  value={program.price}
                  placeholder="가격" inputprops={{ type: 'number', min: '1', max: '100000000' }} onChange={e => onChange(e, 'price', parseInt)} />
              </FormGroup>
            </Col>

          </Row>

          <Row className="align-items-center">
            <h4>기타 설정</h4>
          </Row>
          {/* 추천설정 */}
          <Row className="align-items-center">
            <Col>
              <FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      defaultChecked={program.isRecommandation}
                      type="checkbox"
                      onChange={(e) => setProgram({ ...program, isRecommandation: e.target.checked })}
                    />
                    <span className="form-check-sign" />
                    추천 리스트에 보이기
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Select
                  disabled={!program.isRecommandation}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="recommandationPriority"
                  value={program.recommandationPriority}
                  onChange={value => {
                    // console.log('value', value);
                    if (value !== program.recommandationPriority) {
                      setProgram({ ...program, recommandationPriority: value })
                      // console.log('program', program);
                    }
                  }}
                  options={[
                    {
                      value: "",
                      label: "우선순위",
                      isDisabled: true
                    },
                    { value: "1", label: "최우선" },
                    { value: "2", label: "우선" },
                    { value: "3", label: "보통" },
                    { value: "4", label: "낮음" },
                    { value: "5", label: "아주낮음" },
                  ]}
                  placeholder="노출우선순위 정하기"
                />
              </FormGroup>
            </Col>
          </Row>

          {/* 초안 */}
          <Row className="align-items-center">
            <Col>
              <FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      defaultChecked={program.isDraft}
                      defaultValue=""
                      type="checkbox"
                      onChange={(e) => setProgram({ ...program, isDraft: e.target.checked })}
                    />
                    <span className="form-check-sign" />
                    초안
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className='text-right'>
              <Button color="primary" disabled={!isVerified || onSaving || !isModified} onClick={onSave}>저장</Button>
              <Button color="warning" onClick={onCancel}>취소</Button>
            </Col>
          </Row>

        </Form>

      </div>
    </>
  )
}

ProgramForm.propTypes = {

}

export default ProgramForm

