export const PROGRAM = {
  ADD: {
    REQUEST: "ADD_PROGRAM_REQUEST",
    SUCCESS: "ADD_PROGRAM_SUCCESS",
    FAILURE: "ADD_PROGRAM_FAILURE"
  },
  GET: {
    REQUEST: "GET_PROGRAM_REQUEST",
    SUCCESS: "GET_PROGRAM_SUCCESS",
    FAILURE: "GET_PROGRAM_FAILURE"
  },
  LIST: {
    REQUEST: "GET_PROGRAMS_REQUEST",
    SUCCESS: "GET_PROGRAMS_SUCCESS",
    FAILURE: "GET_PROGRAMS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_PROGRAM_REQUEST",
    SUCCESS: "UPDATE_PROGRAM_SUCCESS",
    FAILURE: "UPDATE_PROGRAM_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_PROGRAM_REQUEST",
    SUCCESS: "REMOVE_PROGRAM_SUCCESS",
    FAILURE: "REMOVE_PROGRAM_FAILURE"
  }
};

export const USER = {
  UPDATE: {
    REQUEST: "UPDATE_USER_REQUEST",
    SUCCESS: "UPDATE_USER_SUCCESS",
    FAILURE: "UPDATE_USER_FAILURE"
  }
};

export const INSTITUTE = {
  ADD: {
    REQUEST: "ADD_INSTITUTE_REQUEST",
    SUCCESS: "ADD_INSTITUTE_SUCCESS",
    FAILURE: "ADD_INSTITUTE_FAILURE"
  },
  GET: {
    REQUEST: "GET_INSTITUTE_REQUEST",
    SUCCESS: "GET_INSTITUTE_SUCCESS",
    FAILURE: "GET_INSTITUTE_FAILURE"
  },
  LIST: {
    REQUEST: "GET_INSTITUTES_REQUEST",
    SUCCESS: "GET_INSTITUTES_SUCCESS",
    FAILURE: "GET_INSTITUTES_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_INSTITUTE_REQUEST",
    SUCCESS: "UPDATE_INSTITUTE_SUCCESS",
    FAILURE: "UPDATE_INSTITUTE_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_INSTITUTE_REQUEST",
    SUCCESS: "REMOVE_INSTITUTE_SUCCESS",
    FAILURE: "REMOVE_INSTITUTE_FAILURE"
  }
};

export const QUESTION = {
  ADD: {
    REQUEST: "ADD_QUESTION_REQUEST",
    SUCCESS: "ADD_QUESTION_SUCCESS",
    FAILURE: "ADD_QUESTION_FAILURE"
  },
  GET: {
    REQUEST: "GET_QUESTION_REQUEST",
    SUCCESS: "GET_QUESTION_SUCCESS",
    FAILURE: "GET_QUESTION_FAILURE"
  },
  LIST: {
    REQUEST: "GET_QUESTIONS_REQUEST",
    SUCCESS: "GET_QUESTIONS_SUCCESS",
    FAILURE: "GET_QUESTIONS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_QUESTION_REQUEST",
    SUCCESS: "UPDATE_QUESTION_SUCCESS",
    FAILURE: "UPDATE_QUESTION_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_QUESTION_REQUEST",
    SUCCESS: "REMOVE_QUESTION_SUCCESS",
    FAILURE: "REMOVE_QUESTION_FAILURE"
  }
};

export const APPLICATION = {
  ADD: {
    REQUEST: "ADD_APPLICATION_REQUEST",
    SUCCESS: "ADD_APPLICATION_SUCCESS",
    FAILURE: "ADD_APPLICATION_FAILURE"
  },
  GET: {
    REQUEST: "GET_APPLICATION_REQUEST",
    SUCCESS: "GET_APPLICATION_SUCCESS",
    FAILURE: "GET_APPLICATION_FAILURE"
  },
  LIST: {
    REQUEST: "GET_APPLICATIONS_REQUEST",
    SUCCESS: "GET_APPLICATIONS_SUCCESS",
    FAILURE: "GET_APPLICATIONS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_APPLICATION_REQUEST",
    SUCCESS: "UPDATE_APPLICATION_SUCCESS",
    FAILURE: "UPDATE_APPLICATION_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_APPLICATION_REQUEST",
    SUCCESS: "REMOVE_APPLICATION_SUCCESS",
    FAILURE: "REMOVE_APPLICATION_FAILURE"
  }
};

export const PREAPPLICATION = {
  ADD: {
    REQUEST: "ADD_PREAPPLICATION_REQUEST",
    SUCCESS: "ADD_PREAPPLICATION_SUCCESS",
    FAILURE: "ADD_PREAPPLICATION_FAILURE"
  },
  GET: {
    REQUEST: "GET_PREAPPLICATION_REQUEST",
    SUCCESS: "GET_PREAPPLICATION_SUCCESS",
    FAILURE: "GET_PREAPPLICATION_FAILURE"
  },
  LIST: {
    REQUEST: "GET_PREAPPLICATIONS_REQUEST",
    SUCCESS: "GET_PREAPPLICATIONS_SUCCESS",
    FAILURE: "GET_PREAPPLICATIONS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_PREAPPLICATION_REQUEST",
    SUCCESS: "UPDATE_PREAPPLICATION_SUCCESS",
    FAILURE: "UPDATE_PREAPPLICATION_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_PREAPPLICATION_REQUEST",
    SUCCESS: "REMOVE_PREAPPLICATION_SUCCESS",
    FAILURE: "REMOVE_PREAPPLICATION_FAILURE"
  }
};

export const REGION = {
  ADD: {
    REQUEST: "ADD_REGION_REQUEST",
    SUCCESS: "ADD_REGION_SUCCESS",
    FAILURE: "ADD_REGION_FAILURE"
  },
  GET: {
    REQUEST: "GET_REGION_REQUEST",
    SUCCESS: "GET_REGION_SUCCESS",
    FAILURE: "GET_REGION_FAILURE"
  },
  LIST: {
    REQUEST: "GET_REGIONS_REQUEST",
    SUCCESS: "GET_REGIONS_SUCCESS",
    FAILURE: "GET_REGIONS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_REGION_REQUEST",
    SUCCESS: "UPDATE_REGION_SUCCESS",
    FAILURE: "UPDATE_REGION_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_REGION_REQUEST",
    SUCCESS: "REMOVE_REGION_SUCCESS",
    FAILURE: "REMOVE_REGION_FAILURE"
  }
};

export const LOCAL_HELPER = {
  ADD: {
    REQUEST: "ADD_LOCAL_HELPER_REQUEST",
    SUCCESS: "ADD_LOCAL_HELPER_SUCCESS",
    FAILURE: "ADD_LOCAL_HELPER_FAILURE"
  },
  GET: {
    REQUEST: "GET_LOCAL_HELPER_REQUEST",
    SUCCESS: "GET_LOCAL_HELPER_SUCCESS",
    FAILURE: "GET_LOCAL_HELPER_FAILURE"
  },
  LIST: {
    REQUEST: "GET_LOCAL_HELPERS_REQUEST",
    SUCCESS: "GET_LOCAL_HELPERS_SUCCESS",
    FAILURE: "GET_LOCAL_HELPERS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_LOCAL_HELPER_REQUEST",
    SUCCESS: "UPDATE_LOCAL_HELPER_SUCCESS",
    FAILURE: "UPDATE_LOCAL_HELPER_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_LOCAL_HELPER_REQUEST",
    SUCCESS: "REMOVE_LOCAL_HELPER_SUCCESS",
    FAILURE: "REMOVE_LOCAL_HELPER_FAILURE"
  }
};

export const POST = {
  ADD: {
    REQUEST: "ADD_POST_REQUEST",
    SUCCESS: "ADD_POST_SUCCESS",
    FAILURE: "ADD_POST_FAILURE"
  },
  GET: {
    REQUEST: "GET_POST_REQUEST",
    SUCCESS: "GET_POST_SUCCESS",
    FAILURE: "GET_POST_FAILURE"
  },
  LIST: {
    REQUEST: "GET_POSTS_REQUEST",
    SUCCESS: "GET_POSTS_SUCCESS",
    FAILURE: "GET_POSTS_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_POST_REQUEST",
    SUCCESS: "UPDATE_POST_SUCCESS",
    FAILURE: "UPDATE_POST_FAILURE"
  },
  REMOVE: {
    REQUEST: "REMOVE_POST_REQUEST",
    SUCCESS: "REMOVE_POST_SUCCESS",
    FAILURE: "REMOVE_POST_FAILURE"
  }
};

export const INFO = {
  GET: {
    REQUEST: "GET_INFO_REQUEST",
    SUCCESS: "GET_INFO_SUCCESS",
    FAILURE: "GET_INFO_FAILURE"
  },
  UPDATE: {
    REQUEST: "UPDATE_INFO_REQUEST",
    SUCCESS: "UPDATE_INFO_SUCCESS",
    FAILURE: "UPDATE_INFO_FAILURE"
  }
};

