import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFirestoreConnect } from 'react-redux-firebase';

import _ from 'lodash';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Progress,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import ReactDatetime from "react-datetime";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { categories } from 'variables/constants';
import { regions } from 'variables/constants';
import { schoolAge } from 'variables/constants';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { isValidPhoneNumber } from 'helpers/validation';
import { isValidEmailAddress } from 'helpers/validation';


const initUser = {
  schedules: [],
  checklist: [],
  photos: [], // {file, description}
};

function PushNotificationForm(props) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const [user, setUser] = useState(props.currentItem ? _.cloneDeep(props.currentItem) : _.cloneDeep(initUser));
  const [docId, setDocId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [verification, setVerification] = useState({
    name: { isValid: false, message: null },
    nickname: { isValid: false, message: null },
    profile_image_url: { isValid: false, message: null },
    phone: { isValid: false, message: null },
    email: { isValid: false, message: null },
  });
  const [isVerified, setIsVerified] = useState(false);
  const [onSaving, setOnSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    firebase.auth().currentUser.getIdTokenResult()
      .then(({ claims }) => {
        if (!claims.admin) {
          basicAlert('글 작성 권한이 없습니다', () => props.toggle(), null);
        }
      });
    const docId = (props.currentItem && props.currentItem.id) || firestore.collection('users').doc().id;
    // console.log('docId', docId);
    setDocId(docId);
    return () => {
      // cleanup
    };
  }, []);

  //verification
  useEffect(() => {
    const {
      name,
      nickname,
      profile_image_url,
      phone,
      email,
      photos
    } = user;
    setVerification({
      name: { isValid: name && name.length > 1, message: '두 글자 이상 입력해주세요' },
      nickname: { isValid: nickname && nickname.length > 1, message: '두 글자 이상 입력해주세요' },
      phone: { isValid: isValidPhoneNumber(phone), message: '+821012345678 형식으로 입력해주세요' },
      email: { isValid: isValidEmailAddress(email), message: '정확한 이메일을 입력해주세요' },
      photos: { isValid: photos && photos.filter(photo => (photo.file || photo.url) && photo.description).length > 0, message: '최소 이미지 하나는 추가해주세요' }
    });

    setIsModified(props.currentItem === null
      ? !_.isEqual(initUser, user)
      : !_.isEqual(props.currentItem, user)
    );

  }, [user]);

  // check verified all
  useEffect(() => {
    // console.log('verfication', verification);
    const invalidItems = _.filter(verification, (value, key) => value.isValid !== true);
    console.log('invalidItems', invalidItems);
    const isVerified = invalidItems.length === 0;
    // console.log('isVerfied', isVerified);
    setIsVerified(isVerified);
  }, [verification])

  const verificationIndicator = (id) => {
    // console.log("verification[id].isValid", verification[`${id}`].isValid);
    if (verification[`${id}`].isValid) {
      return <i className="nc-icon nc-check-2" style={{ color: 'lightgreen', fontSize: '1.5em' }} />
    } else {
      return <><i className="nc-icon nc-alert-circle-i" style={{ color: 'red', fontSize: '1.5em' }} id={`${id}_invalid`} />
        <UncontrolledTooltip target={`${id}_invalid`}>{verification[`${id}`].message}</UncontrolledTooltip>
      </>
    }
  }

  const onChange = (e, name, typeFn) => {
    const value = e.target.value;
    setUser({ ...user, [name]: typeFn ? typeFn(value) : value });
  }

  const onSave = async () => {
    console.log('press save');

    if (!isVerified) {
      basicAlert('값을 확인해주세요');
      return;
    }

    setOnSaving(true);

    const { photos } = user;
    const path = `users/${docId}`;
    // console.log('photos', photos);
    const needUploadFiles = photos.filter(item => item.file);
    const timeStamps = props.currentItem
      ? { updatedAt: firestore.FieldValue.serverTimestamp() }
      : {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp()
      }

    if (needUploadFiles.length > 0) {
      const alreadyUploadedFiles = photos.filter(item => item.url);
      return firebase.uploadFiles(path, needUploadFiles.map(photo => photo.file))
        .then((uploadingInfo) => {
          // console.log('uploading info', uploadingInfo);
          return Promise.all(uploadingInfo.map(({ uploadTaskSnapshot }) => uploadTaskSnapshot.ref.getDownloadURL()))
        })
        .then(urls => {
          // console.log('urls', urls);
          const newlyUploadedPhotos = needUploadFiles.map((item, index) => ({ url: urls[index], description: item.description }));
          const newPhotos = [...alreadyUploadedFiles, ...newlyUploadedPhotos];
          // console.log('newPhotos', newPhotos);

          setUser({ ...user, photos: newPhotos }); // this dose not change program immediately
          // I just wrote this line for in case this modal is not closed.

          return firestore
            .collection('users')
            .doc(docId)
            .set({ ...user, photos: newPhotos, ...timeStamps }, { merge: true })
            .then(() => props.toggle())
            .catch(e => {
              if (e.code === "permission-denied") {
                basicAlert('유저 생성 권한이 없습니다');
              }
              setOnSaving(false);
            })
        })
        .catch(err => {
          console.error('error uploading files', err)
          setOnSaving(false);
          basicAlert('유저 생성에 실패했습니다.');
        })
    } else {
      return firestore
        .collection('users')
        .doc(docId)
        .set({ ...user, photos: [], ...timeStamps }, { merge: true })
        .then(() => props.toggle())
        .catch(e => {
          if (e.code === "permission-denied") {
            basicAlert('유저 생성 권한이 없습니다');
          }
          setOnSaving(false);
        })
    }

  }

  const onCancel = () => {
    if (isModified) {
      props.closeConfirm();
    } else {
      props.toggle();
    }
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const addPhoto = () => {
    setUser({ ...user, photos: [...user.photos, { file: null, description: '' }] });
  }

  const deletePhoto = (idx) => {
    const newPhotos = user.photos.filter((item, i) => i !== idx);
    setUser({ ...user, photos: newPhotos });
  }

  return (
    <>
      <div className="content">
        {alert}
        <Form action="#" method="#">
          <Row>
            <h4>일반 정보</h4>
          </Row>
          {/* 고객 성함 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>고객 성함</label></Col>
                <Col className="text-right">{verificationIndicator("name")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.name}
                  placeholder="고객님의 이름을 입력해주세요"
                  onChange={e => onChange(e, 'name')} />
              </FormGroup>
            </Col>
          </Row>
          {/* 고객 닉네임 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>고객 닉네임</label></Col>
                <Col className="text-right">{verificationIndicator("nickname")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.nickname}
                  placeholder="고객님의 닉네임을 입력해주세요"
                  onChange={e => onChange(e, 'nickname')} />
              </FormGroup>
            </Col>
          </Row>
          {/* 고객 전화번호 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>고객 전화번호</label></Col>
                <Col className="text-right">{verificationIndicator("phone")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.phone}
                  placeholder="고객님의 전화번호를 입력해주세요"
                  onChange={e => onChange(e, 'phone')} />
              </FormGroup>
            </Col>
          </Row>
          {/* 고객 이메일 */}
          <Row>
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col><label>고객 이메일</label></Col>
                <Col className="text-right">{verificationIndicator("email")}</Col>
              </Row>
              <FormGroup>
                <Input
                  value={user.email}
                  placeholder="고객님의 이메일을 입력해주세요"
                  onChange={e => onChange(e, 'email')} />
              </FormGroup>
            </Col>
          </Row>
          {/* image uploader */}
          <Row className="align-items-center">
            <h4>고객 프로필 사진 업로드</h4>
            <span style={{ width: 20, padding: 5 }} />
            <Button className="btn-icon btn-round" size="sm" color="primary" onClick={addPhoto}>
              <i className="fa fa-plus" />
            </Button>
          </Row>
          {
            user.photos && user.photos.map((item, idx) => <Row key={idx}>
              <Col md={5}>
                <label>사진 업로드</label>
                <FormGroup>
                  {
                    item.url
                      ? <img src={item.url} />
                      : <ImageUpload avatar onChange={({ file }) => {
                        const newPhotos = user.photos.map((item, index) => idx === index ? { ...item, file } : item);
                        setUser({ ...user, photos: newPhotos });
                      }} />
                  }
                </FormGroup>
              </Col>
              <Col>
                <label>이 사진에 대한 설명</label>
                <FormGroup>
                  <Input
                    value={item.description}
                    style={{ height: "100%" }} type="textarea" placeholder="사진설명"
                    onChange={event => {
                      // console.log(event.target.value);
                      const newPhotos = user.photos.map((item, index) => idx === index ? { ...item, description: event.target.value } : item);
                      setUser({ ...user, photos: newPhotos });
                    }} />
                </FormGroup>
              </Col>
              <Col md="2">
                <Button className="btn-round btn-icon" size="sm" color="danger" onClick={() => deletePhoto(idx)}>
                  <i className="fa fa-minus" />
                </Button>
              </Col>
            </Row>)
          }

          <Row>
            <Col className='text-right'>
              <Button color="primary" disabled={!isVerified || onSaving || !isModified} onClick={onSave}>저장</Button>
              <Button color="warning" onClick={onCancel}>취소</Button>
            </Col>
          </Row>

        </Form>

      </div>
    </>
  )
}

PushNotificationForm.propTypes = {

}

export default PushNotificationForm

