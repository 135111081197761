import React from 'react'
import PropTypes from 'prop-types'

import Board from 'views/components/Board.jsx';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';
import ApplicationCheckForm from './forms/ApplicationCheckForm';

function Applications(props) {

  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: 'applications' }
  ]);

  const applications = useSelector(state => state.firestore.ordered.applications);
  const boardEl = useRef(null);

  const data = applications && applications.map((item) => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`applications/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))
  // console.log('data', data);

  return (
    <div className="content">
      <Board
        title="지원서"
        data={data}
        ref={boardEl}
        addable={false}
        columns={[
          {
            Header: "학교",
            accessor: 'program.fullname'
          },
          {
            Header: "이름",
            accessor: "owner.name"
          },
          {
            id: 'createdAt',
            Header: "생성일시",
            accessor: d => d.createdAt ? moment(d.createdAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            id: 'updatedAt',
            Header: "수정일시",
            accessor: d => d.updatedAt ? moment(d.updatedAt.toDate()).format('YYYY-MM-DD HH:mm') : ''
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <ApplicationCheckForm />
      </Board>
    </div>
  )
}

Applications.propTypes = {

}

export default Applications

