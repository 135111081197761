import React from 'react'
import PropTypes from 'prop-types'

import Board from 'views/components/Board.jsx';
import PushNotificationForm from './forms/PushNotificationForm';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useRef, useEffect } from 'react';
import moment from 'moment';

function PushNotifications(props) {

  const firestore = useFirestore();
  useFirestoreConnect([
    { collection: 'pushNotifications' }
  ]);

  const pushNotifications = useSelector(state => state.firestore.ordered.pushNotifications);
  const boardEl = useRef(null);

  const data = pushNotifications && pushNotifications.map((item) => ({
    ...item,
    actions: (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        {/* <Button
          onClick={() => {
            console.log('clicked');
            console.log(item);
            console.log(boardEl);
          }}
          color="info"
          size="sm"
          className="btn-icon btn-link like"
        >
          <i className="fa fa-heart" />
        </Button>{" "} */}
        {/* use this button to add a edit kind of action */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.edit(item);
          }}
          color="warning"
          size="sm"
          className="btn-icon btn-link edit"
        >
          <i className="fa fa-edit" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          onClick={() => {
            console.log('clicked');
            boardEl.current.delete(() => {
              firestore.doc(`pushNotifications/${item.id}`).delete();
            });
          }}
          color="danger"
          size="sm"
          className="btn-icon btn-link remove"
        >
          <i className="fa fa-times" style={{ fontSize: '1.5em' }} />
        </Button>{" "}
      </div>
    )
  }))

  return (
    <div className="content">
      <Board
        title="발송 관리"
        data={data}
        ref={boardEl}
        columns={[
          {
            Header: "종류",
            accessor: "type"
          },
          {
            Header: "대상",
            accessor: "target"
          },
          {
            Header: "메시지",
            accessor: "message"
          },
          {
            Header: "명령",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      >
        <PushNotificationForm />
      </Board>

    </div>
  )
}

PushNotifications.propTypes = {

}

export default PushNotifications

