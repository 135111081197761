import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import { forwardRef, useImperativeHandle } from 'react';


const Board = forwardRef(({ title, formTitle, data, columns, children, addable = true }, ref) => {
  const [modal, setModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [alert, setAlert] = useState(null);

  useImperativeHandle(ref, () => ({
    edit: (item) => {
      setCurrentItem(item);
      setModal(true);
    },
    delete: (callback) => {
      console.log("delete");
      confirm('한번 삭제되면 복구할 수 없습니다. 계속 진행하시겠습니까?', callback);
    }
  }));

  const toggle = () => setModal(!modal);
  const closeConfirm = () => {
    confirm('작성 중인 내용이 사라집니다. 계속 진행하시겠습니까?', () => setModal(false));
  }

  const confirm = (message = "확실합니까?", callbackFn) => {
    basicAlert(message,
      () => {
        callbackFn();
        hideAlert();
      },
      () => hideAlert()
    )
  }

  // alerts
  const basicAlert = (msg, onConfirm, onCancel) => {
    setAlert(<ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={msg || "Here's a message!"}
      onConfirm={() => onConfirm ? onConfirm() : hideAlert()}
      onCancel={() => onCancel ? onCancel() : hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="네"
      cancelBtnText="취소"
      showCancel
    />)
  }

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <>
      {alert}
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{title}</CardTitle>
              {
                addable
                  ? <Button onClick={() => {
                    setCurrentItem(null);
                    setModal(true);
                  }}>Add New</Button>
                  : null
              }
            </CardHeader>
            <CardBody>
              <ReactTable
                data={data}
                filterable
                columns={columns}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        // toggle={closeConfirm}
        size={'lg'}
      >
        <ModalHeader >{formTitle ? `${formTitle}` : `${title} 상세정보`}</ModalHeader>
        <ModalBody>
          {
            React.Children.map(children, child => React.cloneElement(child, { closeConfirm, toggle, currentItem }))
          }
        </ModalBody>
        {/* <ModalFooter>
        </ModalFooter> */}
      </Modal>
    </>
  )
})

Board.propTypes = {

}

export default Board

