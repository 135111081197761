import * as types from 'constants/actionTypes';

console.log(types.PROGRAM.ADD.REQUEST);

export const addProgram = (program) => ({
  type: types.PROGRAM.ADD.REQUEST,
  program
});

export const getProgram = (programId) => ({
  type: types.PROGRAM.GET.REQUEST,
  programId
});

export const getPrograms = () => ({
  type: types.PROGRAM.LIST.REQUEST
});

export const updateProgram = (programId, dataToUpdate) => ({
  type: types.PROGRAM.UPDATE.REQUEST,
  programId,
  dataToUpdate
})

export const removeProgram = (programId) => ({
  type: types.PROGRAM.REMOVE.REQUEST,
  programId
});

