import { all, call, spawn } from 'redux-saga/effects';

import * as helloSagaModule from './helloSaga';

function* rootSaga() {
  const sagas = [
    ...Object.values(helloSagaModule)
  ];

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          console.error(e)
        }
      }
    }))
  );
}

export default rootSaga;