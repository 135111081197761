import * as types from 'constants/actionTypes';
import _ from 'lodash';

const initState = [];

export default function (state = initState, action) {
  switch (action.type) {

    case types.PROGRAM.ADD.SUCCESS: {
      return [action.payload, ...state];
    }

    case types.PROGRAM.LIST.SUCCESS: {
      return action.payload;
    }

    case types.PROGRAM.UPDATE.SUCCESS: {
      const updatedItem = state.payload;
      // const foundItem = _.find(state, { program_id: updatedItem.program_id })
      return state.map(item =>
        item.program_id === updatedItem.program_id
          ? updatedItem
          : item
      )
    }


    default:
      return state;
  }
}